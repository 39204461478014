@import '../../../assets/style/default-imports';

.container {
  overflow: hidden;
  margin-bottom: $double-spacing-vertical;
  @media only screen and (min-width: 1025px) {
    height: 212px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 321px;
  }
  @media screen and (max-width: 767px) {
    height: 558px;
  }
}

.title {
  font-size: 24px;
  font-family: $rebrand-font;
  font-weight: 800;
  @media only screen and (max-width: 767px) {
    align-items: center;
  }
}

.text {
  font-family: $rebrand-font;
  font-weight: 500;
  font-size: 20px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.app_rating {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.column_container {
  border-radius: 12px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    text-align: center;
    margin-right: 0;
    background-image: url('../../../assets/images/rebrand/card-background/BGMobile.png');
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    min-width: 85%;
    background-image: url('../../../assets/images/rebrand/card-background/BGTablet.png');
  }

  @media screen and (min-width: 1025px) {
    min-width: 60%;
    background-image: url('../../../assets/images/rebrand/card-background/BGDesktop.png');
  }
}

.column_left {
  padding-left: $double-spacing-horizontal;
  padding-right: $double-spacing-horizontal;
  padding-top: $double-spacing-vertical;
  padding-bottom: $double-spacing-vertical;
  display: flex;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 67%;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
    .center {
      text-align: center;
    }
  }
}

.column_right {
  @media screen and (min-width: 768px) {
    padding-left: $double-spacing-horizontal;
    padding-right: $double-spacing-horizontal;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 33%;
    padding-left: 0;
  }
}

.image {
  margin-right: $standard-spacing-horizontal;
  margin-top: $standard-spacing-vertical;
}

.links {
  @media only screen and (min-width: 1025px) {
    margin-left: $standard-spacing-horizontal;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: $double-spacing-vertical;
  }
}
