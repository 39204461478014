@import '../../../assets/style/default-imports';

.button {
  display: block;
  cursor: pointer;
  padding: $half-spacing-vertical 0;
  min-width: 160px;
  max-height: 40px;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-family: $font-bold;
  font-size: $font-size-base;
  border-radius: 40px;
  box-sizing: border-box;
  max-width: 280px;
  margin: 0 auto;
  color: $copy;

  &:disabled {
    pointer-events: none;
  }
}

.primary_button {
  @extend .button;
  background-color: $primary;
  border: solid $primary 1px;

  &:hover {
    background-color: $turquoise-tint-50;
  }
}

.secondary_button {
  @extend .button;
  background-color: transparent;
  border: solid $copy 1px;

  &:hover {
    background-color: $white-dark;
  }
}

.secondary_remove_button {
  @extend .secondary_button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary_block_button {
  @extend .button;
  background-color: $secondary;
  border: solid $copy 1px;
  color: $white;

  &:hover {
    background-color: $secondary-hover;
  }

  &:active {
    background-color: darken($color: $secondary-hover, $amount: 10);
  }
}

.secondary_button_turquoise {
  @extend .secondary_button;
  border: solid $primary 1px;
}

.download_button {
  @extend .secondary_button;
  background-color: $white;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  min-width: 100px;
}

.image {
  width: 24px;
  margin-right: 10px;
}

.arrow_image {
  margin-left: 10px;
}

.cross_image {
  margin-right: 10px;
  color: $secondary;
}

.send_button {
  @extend .button;
  background-color: $secondary;
  color: $white;
  border-radius: 28px;
  border: none;
  max-height: 56px;
  padding: 16px;
  transition: all 0.3s;

  &:hover {
    background-color: $secondary-hover;
  }

  &:active {
    background-color: darken($color: $secondary-hover, $amount: 10);
  }
}

.anchor_button {
  background: transparent;
  border: none;
  text-decoration: underline;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: $secondary-hover;
  }

  &:active {
    color: darken($color: $secondary-hover, $amount: 10);
  }
}

.back_button {
  @extend .button;
  background: transparent;
  border-radius: 28px;
  border: solid 2px;
  max-height: 56px;
  padding: 16px;
  gap: 8px;
  transition: all 0.3s;
  color: $secondary;

  &:hover {
    background-color: $white-dark;
  }

  &:active {
    background-color: darken($color: $white-dark, $amount: 10);
  }
}

.choose_file_button {
  @extend .button;
  background: $secondary;
  border-radius: 28px;
  border: solid 2px;
  max-height: 56px;
  padding: 16px;
  transition: all 0.3s;
  color: white;
  margin: 8px;
  max-width: 352px;

  &:hover {
    background-color: lighten($color: $secondary, $amount: 10);
  }

  &:active {
    background-color: lighten($color: $secondary, $amount: 20);
  }
}

.open_new_tab_button {
  @extend .send_button;
  width: 100%;
  border-radius: 8px;
  max-height: 96px;
}

.new_primary_button {
  @extend .button;
  width: 100%;
  border-radius: 8px;
  background-color: $secondary;
  border: solid $copy 1px;
  color: $white;

  &:hover {
    background-color: $secondary-hover;
  }

  &:active {
    background-color: darken($color: $secondary-hover, $amount: 10);
  }
}

.new_secondary_button {
  @extend .button;
  width: 100%;
  border-radius: 8px;
  background-color: transparent;
  border: solid $copy 1px;

  &:hover {
    background-color: $white-dark;
  }
}

.make_call_button {
  @extend .send_button;
}

.arrow_back_image {
  color: $secondary;
  margin-right: 10px;
}

.choose_file_image {
  color: $secondary;
  margin-right: 10px;
}

.open_new_tab_image {
  @extend .arrow_image;
  color: white;
  vertical-align: bottom;
}

.make_call_image {
  @extend .arrow_image;
  color: white;
  vertical-align: bottom;
}

.bank_details {
  @extend .secondary_block_button;
  min-width: 352px;
  display: flex;
  justify-content: center;
  padding: $double-spacing-vertical;
  align-items: center;
}

.blue_arrow {
  @extend .button;
  background: white;
  min-width: 352px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $double-spacing-vertical 0;
  border: solid $secondary 1px;

  .blue_arrow_image {
    padding-left: $half-spacing-horizontal;
    align-self: center;
  }
}

.as_text {
  @extend .button;
  background-color: transparent;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  min-width: 350px;
  padding-right: 0;
}
