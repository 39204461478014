@import "../../../assets/style/default-imports";

.title {
  margin-top: 35px;
  font-weight: bold;
  color: #152939;
}

.text {
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  color: #152939;
  text-align: center;
  margin-bottom: 15px;

  .email {
    display: block;
  }
}

.info {
  text-align: start;
  margin-bottom: calc($double-spacing-vertical * 1.5);
  font-size: 18px;
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
}

.password_requirements {
  margin-block-start: 0;
  margin-block-end: $standard-spacing-vertical;
  padding: 0;
}

.password_header {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: $black;
  display: block;
}