@import "../../../assets/style/default-imports";

.outer_container {
  display: flex;
  flex-direction: column;
  margin-bottom: $double-spacing-vertical;
  align-items: center;
  h3 {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
}

.button_container {
  width: $large-button-width;
}