@import '../../../assets/style/default-imports';

.list {
  font-size: 14px;
  line-height: 20px;
  color: $secondary;
  list-style-type: none;
  display: flex;
  align-items: center;

  .img {
    padding-right: 10px;
  }
}

.password_check_passed {
  @extend .list;
  color: $primary-dark;
}

.error {
  @extend .list;
  color: $error-red;
}
