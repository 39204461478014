@import "../../../assets/style/default-imports";

.info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;
}

.signout {
  cursor: pointer;
  color: $black;
  text-align: center;
  margin-top: $standard-spacing-vertical;
}

.form {
  max-width: fit-content;
  margin: auto;
  // font-weight: 700;
  // font-size: 18px;
  // line-height: 24px;
}

.column_container {
  display: block;
  @media only screen and (min-width: $desktop-container-size) {
    display: flex;
  }
}
.column {
  flex-basis: 50%;
}
.column_left {
  @extend .column;
  @media only screen and (min-width: $desktop-container-size) {
    padding-right: $standard-spacing-horizontal;
  }
}
.column_right {
  @extend .column;
  @media only screen and (min-width: $desktop-container-size) {
    padding-left: $standard-spacing-horizontal;
  }
}

.button_container {
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: $double-spacing-vertical;
}

.button {
  width: 250px;
  padding-left: $standard-spacing-horizontal;
  padding-right: $standard-spacing-horizontal;
}
