// FONTS
$font-fallback: Arial, Helvetica, sans-serif !default;
$font-light: 'FSElliotLight', $font-fallback !default;
$font-regular: 'FSElliotRegular', $font-fallback !default;
$font-bold: 'FSElliotBold', $font-fallback !default;
$font-heavy: 'FSElliotHeavy', $font-bold, 'Arial Black', $font-fallback !default;
$font-family-base: $font-regular !default;
$font-size-base: 16px !default;
$half-size-large: 18px;
$line-height-base: 1.25;

$rebrand-font: 'PlusJakartaSans', $font-fallback !default;

$page-title-font-family: $font-heavy, $font-regular;

// Font size and line height mixin
@mixin font-size($font-size, $line-height: false) {
  font-size: rem($font-size); /* #{$font-size} */

  @if $line-height != false {
    line-height: calc($line-height / $font-size); /* #{$line-height} */
  }
}

$h1-font-size-mobile: 32px;
$h1-line-height-mobile: 34px;
$h1-font-size-tablet: 40px;
$h1-line-height-tablet: 44px;
$h1-font-size-desktop: 60px;
$h1-line-height-desktop: 60px;

$h2-font-size-mobile: 28px;
$h2-line-height-mobile: 34px;
$h2-font-size-tablet: 30px;
$h2-line-height-tablet: 36px;
$h2-font-size-desktop: 36px;
$h2-line-height-desktop: 44px;

$h3-font-size-mobile: 24px;
$h3-line-height-mobile: 30px;
$h3-font-size-tablet: 26px;
$h3-line-height-tablet: 32px;
$h3-font-size-desktop: 28px;
$h3-line-height-desktop: 34px;

$h4-font-size-mobile: 22px;
$h4-line-height-mobile: 28px;
$h4-font-size-tablet: 24px;
$h4-line-height-tablet: 30px;
$h4-font-size-desktop: 24px;
$h4-line-height-desktop: 30px;

$h5-font-size-mobile: 20px;
$h5-line-height-mobile: 28px;
$h5-font-size-tablet: 20px;
$h5-line-height-tablet: 28px;
$h5-font-size-desktop: 20px;
$h5-line-height-desktop: 28px;

$lead-font-size-mobile: 20px;
$lead-line-height-mobile: 24px;
$lead-font-size-tablet: 22px;
$lead-line-height-tablet: 28px;
$lead-font-size-desktop: 24px;
$lead-line-height-desktop: 30px;

$default-font-size-mobile: 16px;
$default-line-height-mobile: 24px;
$default-font-size-tablet: 16px;
$default-line-height-tablet: 24px;
$default-font-size-desktop: 18px;
$default-line-height-desktop: 26px;

$small-font-size-mobile: 12px;
$small-line-height-mobile: 20px;
$small-font-size-tablet: 14px;
$small-line-height-tablet: 22px;
$small-font-size-desktop: 14px;
$small-line-height-desktop: 22px;

$form-label-font-family: $font-bold;
$form-required-label-font-family: $font-regular;

$form-label-font-size-mobile: 12px;
$form-label-line-height-mobile: 20px;
$form-label-font-size-tablet: 14px;
$form-label-line-height-tablet: 22px;
$form-label-font-size-desktop: 14px;
$form-label-line-height-desktop: 22px;
