// This file is imported only in the highest level (Index.scss) - do NOT import in SCSS partials!
html,
body {
  font-size: $font-size-base;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

h1 {
  font-family: $page-title-font-family;
  @include font-size($h1-font-size-mobile, $h1-line-height-mobile);
  @include mq("tablet") {
    @include font-size($h1-font-size-tablet, $h1-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($h1-font-size-desktop, $h1-line-height-desktop);
  }
}
h2,
h3,
h4,
h5,
h6,
strong,
b,
dt {
  font-family: $font-regular;
}
h2 {
  font-family: $font-regular;
  @include font-size($h2-font-size-mobile, $h2-line-height-mobile);
  @include mq("tablet") {
    @include font-size($h2-font-size-tablet, $h2-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($h2-font-size-desktop, $h2-line-height-desktop);
  }
}
h3 {
  @include font-size($h3-font-size-mobile, $h3-line-height-mobile);
  @include mq("tablet") {
    @include font-size($h3-font-size-tablet, $h3-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($h3-font-size-desktop, $h3-line-height-desktop);
  }
}
h4 {
  @include font-size($h4-font-size-mobile, $h4-line-height-mobile);
  @include mq("tablet") {
    @include font-size($h4-font-size-tablet, $h4-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($h4-font-size-desktop, $h4-line-height-desktop);
  }
}
h5 {
  @include font-size($h5-font-size-mobile, $h5-line-height-mobile);
  @include mq("tablet") {
    @include font-size($h5-font-size-tablet, $h5-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($h5-font-size-desktop, $h5-line-height-desktop);
  }
}
.lead {
  @include font-size($lead-font-size-mobile, $lead-line-height-mobile);
  @include mq("tablet") {
    @include font-size($lead-font-size-tablet, $lead-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($lead-font-size-desktop, $lead-line-height-desktop);
  }
}
h6, p, li, div {
  @include font-size($default-font-size-mobile, $default-line-height-mobile);
  @include mq("tablet") {
    @include font-size($default-font-size-tablet, $default-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($default-font-size-desktop, $default-line-height-desktop);
  }
}

small {
  @include font-size($small-font-size-mobile, $small-line-height-mobile);
  @include mq("tablet") {
    @include font-size($small-font-size-tablet, $small-line-height-tablet);
  }
  @include mq("desktop") {
    @include font-size($small-font-size-desktop, $small-line-height-desktop);
  }
}
