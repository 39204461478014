@import "../../assets/style/default-imports";

.password_requirements {
  margin-block-start: 0;
  margin-block-end: 0;
}

.passwordCheckPassed {
  color: green;
  list-style-type: none;
  &::marker {
    content: "\2713\0020";
  }
}

.forgot_password {
  text-decoration: underline;
  cursor: pointer;
}

.links {
  p {
    margin-block-start: 0px;
  }
  .link {
    color: $black;
  }
}

.confirmResetPassword {
  text-align: center;
}

.footer_wrapper {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.form_container {
  padding: 16px 18px 24px 18px;
  width: 368px;
  box-sizing: border-box;
}

.sign_in_footer_header {
  margin-bottom: 0;
  font-family: $font-bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}
