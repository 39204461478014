@import "../../../assets/style/default-imports";

.info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;
}

.title {
  margin-bottom: 0;
  font-family: $font-bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}

.footer_info {
  @extend .info;
  margin-top: 0;
}
