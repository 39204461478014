@import "../../../assets/style/default-imports";

  .card_size_container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-width: 160px;
  }

  .card_text{
    text-align: center;
  }

  .card_image{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }