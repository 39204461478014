$breakpoints: (
  "mobile": 320px,
  "tablet": 768px,
  "desktop": 1024px,
  "desktop-wide": 1280px,
);

$mobile-container-size: 320px;
$tablet-container-size: 768px;
$desktop-container-size: 940px;
$desktop-wide-container-size: 1200px;

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
