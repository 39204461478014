@import "../../../assets/style/default-imports";

.outer_container {
  padding: $standard-spacing-vertical $standard-spacing-horizontal $standard-spacing-vertical
    $standard-spacing-horizontal;
  background: $secondary;
  border-radius: 24px 24px 0px 0px;
  @include mq("tablet") {
    padding: $standard-spacing;
  }

  > div {
    @include mq(desktop-wide) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.social_section,
.outer_container {
  &,
  a {
    color: $white;
    &:focus {
      outline: none;
    }
  }
  .sr_only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  li {
    list-style: none;
    display: inline-flex;
    margin: 10px;
  }
}

.row_container {
  display: block;
  @include mq(desktop-wide) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
}
.social_section {
  background: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $standard-spacing-vertical;
  @include mq(desktop-wide) {
    justify-content: flex-end;
  }
  li {
    &:not(:first-child) {
      margin-left: $standard-spacing-horizontal;
    }
  }
  a {
    text-decoration: none;
    display: inline-flex;
    background-position: center;
    background-size: cover;
    width: 24px;
    height: 24px;

    &:hover,
    &:focus {
      position: relative;
      bottom: 2px;
    }
    &.linkedin {
      background: url($icon-social-linkedin);
    }
    &.facebook {
      background: url($icon-social-facebook);
    }
    &.instagram {
      background: url($icon-social-instagram);
    }
    &.twitter {
      background: url($icon-social-twitter);
    }
    &.youtube {
      width: 36px;
      height: 25px;
      background: url($icon-social-youtube);
    }
  }
}

.logo_section {
  display: flex;
  justify-content: center;
  align-items: center;
  .bcorp_logo {
    height: 80px;
  }
  @include mq(desktop-wide) {
    justify-content: flex-start;
  }
}

.link_section {
  li {
    margin-right: 0;
  }
  a {
    &,
    &:visited {
      display: inline-block;
      padding: $quarter-spacing-vertical $quarter-spacing-horizontal 4px;
      margin: 0 $quarter-spacing-horizontal;
      text-decoration: none;
      border-bottom: 1px solid $white;
      font-size: $font-size-base;
    }
    &:hover,
    &:focus {
      color: $primary-lightest;
      border-bottom: 2px solid $primary-lightest;
      padding-bottom: 3px;

      &::after {
        bottom: 1px;
        height: 2px;
        background: $primary-lightest;
      }
    }
  }
}
.copyright {
  text-align: center;
  margin: $standard-spacing-vertical 0 0;
  font-size: $font-size-base;
  @include mq(desktop-wide) {
    margin: 0;
    text-align: left;
  }
}
