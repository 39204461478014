@import '../../assets/style/default-imports';

.outer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: $standard-spacing-vertical auto;
  max-width: 1024px;
}

.message_text {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
