@import '../../../../assets/style/default-imports';

.rating {
  margin-top: $standard-spacing-vertical;
  display: flex;
  flex-basis: row;
}

.rating_star {
  margin-right: $half-spacing-horizontal;
  height: 20px;
  width: 20px;
}

.rating_container {
  display: flex;
}

.rating_text {
  font-family: $rebrand-font;
  font-weight: 700;
  font-size: 16px;
  margin-right: 3px;
}

.rating_number {
  @extend .rating_text;
  font-weight: 800;
}

.rating_reviews {
  @extend .rating_text;
  font-weight: 400;
}
