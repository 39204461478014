@import "./assets/style/font-import";
@import "./assets/style/default-imports";
@import "./assets/style/typography";

body {
  background-color: $grey-light;
}

.link_nav {
  text-decoration: none;

  & button {
    border-color: $primary;
  }
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-container-width-max: 100vw;
  max-width: 100vw;
}

[data-amplify-router] {
  margin: auto;
}

[data-amplify-authenticator] [data-amplify-router] {
  width: 368px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 16px;
  --amplify-components-authenticator-router-border-style: none;
  border: 1px solid var(--amplify-primary-shade);
  -webkit-box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  margin-bottom: calc($double-spacing-horizontal * 3);

  @media screen and (min-width: 900px) {
    width: 640px;
  }
}

[data-amplify-authenticator-signin] {
  display: none;
}

[data-amplify-authenticator-confirmsignup] {
  p.amplify-text {
    display: none !important;
  }

  div.amplify-flex {
    gap: 0;
  }

  button.amplify-button--primary {
    display: none;
  }

  button.amplify-button {
    margin-left: 50px;
    margin-right: 50px;
    background-color: $primary;
    border-radius: 40px;
    color: $copy;
    font-family: $font-bold;
    font-size: $font-size-base;
    border: solid $primary 1px;

    &:hover {
      background-color: $turquoise-tint-50;
    }
  }

  input.amplify-input {
    display: none;
  }
}

[data-amplify-authenticator-confirmresetpassword] {
  p.amplify-text {
    display: none !important;
  }

  div.amplify-flex {
    gap: 0;
  }

  input.amplify-input {
    display: none;
  }

  button.amplify-field__show-password {
    display: none;
  }

  button.amplify-button--primary {
    display: none;
  }

  button.amplify-button {
    margin-left: 50px;
    margin-right: 50px;
    background-color: $primary;
    border-radius: 40px;
    color: $copy;
    font-family: $font-bold;
    font-size: $font-size-base;
    border: solid $primary 1px;

    &:hover {
      background-color: $turquoise-tint-50;
    }
  }

  input.amplify-input {
    display: none;
  }
}

.flatpickr-input {
  width: fit-content !important;
  padding: 5px 10px;
  background-color: $form-background-colour;
  border: 1px solid $primary-dark; // revert if UI changes shouldn't be global
  height: rem(40px);
  border-radius: 0.25rem;
}