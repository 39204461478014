/* This needs scss-ing */
@import "./assets/style/default-imports";

.amplify-button[data-variation="primary"] {
  background-color: $primary;
  border-radius: 40px;
  color: black;
  &:hover {
    background-color: $turquoise-tint-50;
  }
}

h3.amplify-heading {
  font-size: 24px;
  font-weight: 600;
}
