@import '../../../../assets/style/default-imports';

.container {
  display: block;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    align-items: center;
  }
}

.qrCode {
  height: auto;
  margin: 0 auto;
  max-width: 100px;
  width: 100%;

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 72px;
    margin: 0;
  }
}

.text {
  font-size: 14px;
  font-family: $rebrand-font;
  font-weight: 500;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 10px;
  }
}
