@import "../../../assets/style/default-imports";

.error_message_wrapper {
  background: rgba(195, 33, 55, 0.08);
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 18px;
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
}

.error_image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.first_line_wrapper {
  display: flex;
}

.second_line_wrapper {
  padding-top: 16px;
}

.error_message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $navy;
  margin: 0;
  display: block;

  &_prefix {
    font-family: $font-bold;
  }
}

.info_wrapper {
  display: flex;
  box-sizing: border-box;
}

.register_info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;

  .bold {
    font-family: $font-bold;
  }
}

.password_header {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  color: $black;
  display: block;
}

.password_requirements {
  margin-block-start: 0;
  margin-block-end: $standard-spacing-vertical;
  padding: 0;
  margin-bottom: 32px;
}

.no_spacing {
  margin-bottom: 0;
}

.resend_button_wrapper {
  margin: 0;
}

.image_container {
  display: flex;
  justify-content: center;
}

.imgage_wrapper {
  @extend .image_container;
  .image {
    height: 132px;
  }
}

.link {
  color: black;

  &:hover {
    color: $primary;
  }

  &:active {
    color: darken($color: $primary, $amount: 10);
  }
}

.login_redirect {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $secondary;
  margin-bottom: 0;
  margin-top: 32px;
}

.text {
  font-family: $font-bold;
}
