@import "../../../../assets/style/default-imports";

.outer_container {
  padding-top: $double-spacing-vertical;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
}

.inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--amplify-primary-shade);
  -webkit-box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  margin-bottom: 3rem;
}

.back_button_wrapper {
  margin-bottom: $standard-spacing-vertical;
  padding-bottom: 5px;
  border-bottom: 2px solid #dcdcdc;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  img {
    margin-right: 5px;
  }
}

.form_wrapper {
  display: flex;
  justify-content: center;

  @media screen and (min-width: 900px) {
    width: 640px;
  }
}

.form_container {
  padding: 16px 18px 24px 18px;
  width: 368px;
  box-sizing: border-box;

  & button {
    &:disabled {
      background-color: #b6b6b6;
      color: white;
      opacity: 1;
      border: solid transparent 1px;
    }
  }
}

.header {
  font-family: $font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 32px;
  color: $secondary;
  margin-top: $double-spacing-vertical;
  padding-bottom: $standard-spacing-vertical;
  border-bottom: 4px solid #58efd0;
  margin-bottom: $standard-spacing-vertical;
}

.image_container {
  display: flex;
  justify-content: center;
  height: 133px;
}

.logo_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
  margin-bottom: $double-spacing-vertical;

  img {
    display: flex;
    align-items: center;
  }
}