@import "../../../assets/style/default-imports";

.outer_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: $double-spacing-vertical;
    margin-top: $double-spacing-vertical;
    @media screen and (max-width: $desktop-wide-container-size) {
      justify-content: space-evenly;
    }
  }