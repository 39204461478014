$standard-spacing-vertical: 1rem;
$standard-spacing-horizontal: 1rem;
$standard-spacing: $standard-spacing-vertical $standard-spacing-horizontal;

$double-spacing-vertical: calc($standard-spacing-vertical * 2);
$double-spacing-horizontal: calc($standard-spacing-horizontal * 2);
$double-spacing: $double-spacing-vertical $double-spacing-horizontal;

$half-spacing-vertical: calc($standard-spacing-vertical / 2);
$half-spacing-horizontal: calc($standard-spacing-horizontal / 2);
$half-spacing: $half-spacing-vertical $half-spacing-horizontal;

$quarter-spacing-vertical: calc($half-spacing-vertical / 2);
$quarter-spacing-horizontal: calc($half-spacing-horizontal / 2);
$quarter-spacing: $quarter-spacing-vertical $quarter-spacing-horizontal;

$form-field-border-radius: 0.25rem;
$form-field-width: 25rem;

$tile-max-midth: 330px;

$price-label-size-mobile: 58px;
$price-label-size-tablet: 72px;
$price-label-size-desktop: 87px;
$price-label-size-desktop-wide: 120px;

$footer-height-mobile: 233px;
$footer-height-tablet: 145px;
$footer-height-desktop: 147px;
$footer-height-desktop-wide: 106px;

$header-height-mobile: 55px;
$header-height-tablet-up: 100px;

$large-button-width: 280px;