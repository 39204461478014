// TODO: review and set these from design. Currently this is just from a previous project...
@import 'src/assets/style/helper-functions';

// brand colors from Figma
$primary: #00e6b8;
$primary-light: #57efd0;
$primary-lightest: #abf7e8;
$primary-lightest-50: #e6f6f3;
$primary-dark: #009577;

$secondary: #192550;
$secondary-light: #b1bde7;
$secondary-lightest: #e0e5f5;
$secondary-dark: #0f152e;
$secondary-hover: $primary-dark;

$black: #000 !default;
$white: #fff !default;
$navy: #0d2835 !default;

$grey: #646a7e;

$error-red: #c32137;

$copy: $navy !default;

//Misc colors that are used
$limegreen: #99cc33;
$green-light: #bff3e8;
$turquoise-tint-50: #80f3dc !default;
$white-dark: #efefef !default;
$red-light: #fae9eb;
$visual-tracker-progress: #dddddd;
$visual-tracker-step-name: #7f7f7f;
$message-background: #fcfaec;
$form-background-colour: $white;
$grey-background: #f8f9fe;
$primary-dark-new: #1e1e22;
$info-light: #e2dcfe;

// Need to work out if these are used
$red: #e02020 !default;
$grey-mid: #b4b9c3;
$grey-light: #f2f4f8;
$grey-dark: #e0e0e0;
$red-new: #d11111;

$error-colour: $red;
$error-border-colour: $error-colour;
$error-background-colour: tint($error-colour, 92%); //#fdeeee
$error-border-colour: tint($error-colour, 70%);
$error-text-colour-new: $red-new;

$form-border-colour: $grey-mid !default;
$form-border-colour-new: $primary-dark !default;
$error-border-colour_new: $red-new !default;

$decorative-colour: tint($navy, 80%) !default;
