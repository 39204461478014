@import '../../assets/style/default-imports';

.container {
  display: block;
  text-align: center;
}

.store_link {
  margin-top: $double-spacing-vertical;
}
