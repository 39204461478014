@import "../../../assets/style/default-imports";

.info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;
}

.error_message_wrapper {
  background: rgba(195, 33, 55, 0.08);
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 18px;
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
}

.error_image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.first_line_wrapper {
  display: flex;
}

.second_line_wrapper {
  padding-top: 16px;
}

.error_message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $navy;
  margin: 0;
  display: block;

  &_prefix {
    font-family: $font-bold;
  }
}

.footer_title {
  margin-bottom: 0;
  font-family: $font-bold;
  font-size: 18px;
  line-height: 24px;
  color: $black;
}

.footer_info {
  @extend .info;
  margin-top: 0;
}
