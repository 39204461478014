@import "../../../assets/style/default-imports";

.container {
  box-sizing: border-box;
  padding: $double-spacing-vertical $standard-spacing-horizontal;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - #{$footer-height-mobile} + #{$header-height-mobile});

  @include mq(tablet) {
    min-height: calc(100vh - (#{$footer-height-tablet} + #{$header-height-tablet-up}));
  }
  @include mq(desktop) {
    min-height: calc(100vh - (#{$footer-height-desktop} + #{$header-height-tablet-up}));
  }
  @include mq(desktop-wide) {
    min-height: calc(100vh - (#{$footer-height-desktop-wide} + #{$header-height-tablet-up}));
  }

  img {
    max-width: 100%;
  }
  p {
    margin: 0;
    &:not(:first-of-type) {
      margin-bottom: $double-spacing-vertical;
    }
  }

  .button_container {
    display: flex;
    justify-content: center;
    min-width: 280px;
  }
}
