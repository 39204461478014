@import "../../../assets/style/default-imports";

.forgot_password_info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;
}

.text {
  box-sizing: border-box;
  font-weight: bold;
  font-size: 18px;
  color: #152939;
  text-align: start;
  margin-bottom: 15px;
  font-family: $font-bold;
  line-height: 24px;

  .email {
    display: block;
    word-wrap: break-word;
  }
}

.error_message_wrapper {
  background: rgba(195, 33, 55, 0.08);
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 18px;
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
}

.error_message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $navy;
  margin: 0;
  display: block;

  &_prefix {
    font-family: $font-bold;
  }
}

.error_image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.first_line_wrapper {
  display: flex;
}

.info {
  text-align: start;
  margin-bottom: $standard-spacing-vertical;
  font-size: 18px;
  color: $black;
}

.image_container {
  display: flex;
  justify-content: center;
  height: 133px;
}

.link_wrapper {
  margin-bottom: 0;
  margin-top: $double-spacing-vertical;
}

.link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: $primary;
  }

  &:active {
    color: darken($color: $primary, $amount: 10);
  }
}

.second_text_wrapper {
  margin-top: $double-spacing-vertical;
  margin-bottom: 0;
}

.resend_button_wrapper {
  margin: 0;
}
