@import "../../assets/style/default-imports";
.outer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $standard-spacing-vertical;
  padding-right: 16px;
  padding-left: 16px;
}

.header {
  text-align: center;
}

.text {
  margin-bottom: 1rem;
  text-align: center;
}

.app_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1170px;
  margin-bottom: 5rem;
}
