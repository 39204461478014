@font-face {
  font-family: 'FSElliotHeavy';
  font-weight: 900;
  src:
    url('../fonts/FSElliotPro-Heavy.otf') format('opentype'),
    url('../fonts/FSElliotPro-Heavy.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FSElliotBold';
  font-weight: 700;
  src:
    url('../fonts/FSElliotWeb-Bold.woff') format('woff'),
    url('../fonts/FSElliot-Bold.otf') format('opentype'),
    url('../fonts/FSElliot-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FSElliotRegular';
  font-weight: 400;
  src:
    url('../fonts/FSElliotWeb-Regular.woff') format('woff'),
    url('../fonts/FSElliot-Regular.otf') format('opentype'),
    url('../fonts/FSElliot-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'FSElliotLight';
  font-weight: 300;
  src:
    url('../fonts/FSElliotWeb-Light.woff') format('woff'),
    url('../fonts/FSElliot-Light.otf') format('opentype'),
    url('../fonts/FSElliot-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'PlusJakartaSans';
  src: url('../fonts/PlusJakartaSans-VariableFont_wght.ttf');
}
