@import "../../../assets/style/default-imports";

.form_field_container {
  display: flex;
  flex-direction: column;
  padding-bottom: $standard-spacing-vertical;
}

.form_checkbox_field_container {
  @extend .form_field_container;
  flex-direction: row;
}

.form_password_field_container {
  @extend .form_field_container;
  box-sizing: border-box;
}

.form_radio_field_container {
  @extend .form_field_container;
  max-width: fit-content;
}

.field {
  box-sizing: border-box;
  display: inline-block;
  padding: 1px 0.66667rem;
  background-color: $form-background-colour;
  border: 1px solid $secondary-hover; // revert if we don't want to change whole page UI
  height: rem(40px);
  border-radius: 0.25rem;

  &::-ms-expand {
    display: none;
  }
  &:focus {
    padding: 0 0.66667rem;
    border-color: $navy;
    border-width: 1px;
    outline: none;
  }
}

.email_field {
  @extend .field;
  padding: 24px 0.66667rem;
  border: 2px solid $form-border-colour;

  &:focus {
    padding: 24px 0.66667rem;
    border: 2px solid $form-border-colour;
    outline: none;
  }

  &:active {
    border: 2px solid $form-border-colour;
  }
}

.password_wrapper {
  position: relative;
}

.password {
  @extend .field;
  width: 100%;
  padding: 24px 0.66667rem;
  padding-right: $double-spacing-horizontal;
  border: 2px solid $form-border-colour;

  &:focus {
    padding: 24px 0.66667rem;
    padding-right: $double-spacing-horizontal;
    border: 2px solid $form-border-colour;
    outline: none;
  }
}

.textarea {
  @extend .field;
  height: rem(80px);
}

.checkbox {
  margin-left: 1rem;
  &:focus {
    padding: 0 0.66667rem;
    border-color: $navy;
    border-width: 1px;
    outline: none;
  }
}

.radio_label {
  padding: 1rem 1.5rem;
  position: relative;
  z-index: 2;
  float: left;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}

.radio_large {
  width: 120px;
}

.radio_label_unselected {
  @extend .radio_label;
  color: $secondary;
  border: 1px solid $secondary;
}

.radio_label_selected {
  @extend .radio_label;
  background-color: $primary-dark;
  color: white;
}

.radios_container {
  display: flex;
  gap: $standard-spacing-horizontal;
}

.radio_button {
  font-weight: bold;
  color: $black;
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
}

.radio_button_field {
  display: none;
}

.labels_container {
  display: flex;
  justify-content: flex-start;
}

.field_label {
  margin-bottom: $quarter-spacing-vertical;
}

.field_label_bold {
  @extend .field_label;
  font-family: $font-bold;
}

.field_label_bold_new {
  @extend .field_label_bold;
  color: $secondary;
}

.error {
  @extend .field;
  border: 1px solid $error-border-colour;
  &:focus {
    border-color: $error-border-colour;
    border-width: 2px;
  }
}
.error_message {
  margin: 5px 0 0 0;
  color: $error-red;
  font-size: $form-label-font-size-desktop;
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  .error_image {
    margin-right: 5px;
  }
}

.issue_message {
  margin: 5px 0 0 0;
  color: $secondary;
  font-size: $form-label-font-size-desktop;
  display: flex;
  align-items: center;

  .issue_image {
    margin-right: 5px;
  }
}

.password_toggle_icon_wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.password_toggle_icon {
  margin-left: -30px;
  cursor: pointer;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.textareaCount {
  font-family: $font-light;
}

.price_wrapper {
  position: relative;
}

.price_field {
  @extend .field;
  padding-left: 25px !important;
}

.price {
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 18px;
}

.error {
  background: #fdf6f7;
  border: 2px solid $error-red;
  border-radius: 0.25rem;

  &:active {
    border: 2px solid $error-red;
  }
}

.register_label {
  font-size: 18px;
  line-height: 24px;
  color: $secondary;
  font-family: $font-bold;
  margin-bottom: 2px;
}

.large {
  width: 100%;
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: $double-spacing-horizontal;
  border: 2px solid $form-border-colour;

  &:focus {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: $double-spacing-horizontal;
    border: 2px solid $form-border-colour;
    outline: none;
  }
}

.date_of_birth_wrapper {
  display: flex;
  justify-content: space-between;
}

.date_field {
  @extend .field;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: auto;
  color: $visual-tracker-step-name;
  background: url(../../../assets/images/arrow-down.svg) no-repeat 95% 50%;
  padding: 12px 0.66667rem;
  padding-right: calc($double-spacing-horizontal * 1.5);
  border: 2px solid $form-border-colour;

  &:focus {
    border-color: $navy;
    padding: 12px 0.66667rem;
    padding-right: calc($double-spacing-horizontal * 1.5);
    border: 2px solid $form-border-colour;
    outline: none;
  }
}

.success {
  border: 2px solid $primary;
  background-color: #00e6b80f;
  color: $black;

  &:focus {
    border-color: $navy;
    padding: 12px 0.66667rem;
    padding-right: calc($double-spacing-horizontal * 1.5);
    border: 2px solid $primary;
    color: $black;
    outline: none;
  }
}

.error {
  background: #fdf6f7;
  border: 2px solid $error-red;
  border-radius: 0.25rem;

  &:active {
    border: 2px solid $error-red;
  }
}

.calendar {
  height: 24px;
  position: absolute;
  top: 7px;
  right: 10px;
}

.calendar_wrapper {
  position: relative;
  width: fit-content;
}

.disabled_time {
  width: fit-content;
  padding: 5px 10px;
  background-color: $white;
  border: 1px solid #b4b9c3;
  height: 2.5rem;
  border-radius: 0.25rem;
}

.field_new {
  box-sizing: border-box;
  display: inline-block;
  padding: 1px 0.66667rem;
  background-color: $form-background-colour;
  border: 2px solid $form-border-colour-new;
  height: rem(40px);
  border-radius: 0.25rem;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    background-color: none;
    padding: 0 0.66667rem;
    outline: none;
  }
}

.error_new {
  @extend .field_new;
  border: 2px solid $error-border-colour-new;
}

.error_message_new {
  margin: 5px 0 0 0;
  color: $error-text-colour-new;
  font-size: $form-label-font-size-desktop;
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  .error_image {
    margin-right: 5px;
  }
}

.sort_code_container {
  display: flex;
  gap: $standard-spacing-horizontal;
  width: 75%;
}

.error_sort_code {
  @extend .error_new;
  width: 100%;
}

.field_sort_code {
  @extend .field_new;
  width: 100%;
}
