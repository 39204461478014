@import "../../assets/style/default-imports";

.outer_container {
  padding-top: $double-spacing-vertical;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
}

.inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--amplify-primary-shade);
  -webkit-box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  box-shadow: 4px 4px 24px rgba(9, 11, 32, 0.16);
  margin-bottom: 3rem;
}

.image_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
  margin-bottom: $double-spacing-vertical;

  img {
    display: flex;
    align-items: center;
  }
}
