@import "../../../assets/style/default-imports";

.form_container {
  padding: 16px 18px 24px 18px;
  width: 368px;
  box-sizing: border-box;
}

.header {
  font-family: $font-bold;
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 32px;
  color: $secondary;
  margin-top: $double-spacing-vertical;
  padding-bottom: $standard-spacing-vertical;
  border-bottom: 4px solid $primary-light;
  margin-bottom: $standard-spacing-vertical;
}

.image_container {
  display: flex;
  justify-content: center;

  img {
    max-height: 133px;
    width: auto;
  }
}

.info_wrapper {
  display: flex;
  box-sizing: border-box;
}

.sign_in_info {
  font-size: 18px;
  line-height: 24px;
  color: $black;
  margin-bottom: $double-spacing-vertical;

  .bold {
    font-family: $font-bold;
  }
}

.error_message_wrapper {
  background: rgba(195, 33, 55, 0.08);
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 18px;
  display: flex;
  padding-right: 16px;
  align-items: flex-start;
}

.error_image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
}

.first_line_wrapper {
  display: flex;
}

.second_line_wrapper {
  padding-top: 16px;
}

.error_message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $navy;
  margin: 0;
  display: block;

  &_prefix {
    font-family: $font-bold;
  }
}

.link_wrapper {
  margin-top: 0;
}

.link {
  color: black;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
