@import '../../../../assets/style/default-imports';

.container {
  margin-top: $standard-spacing-vertical;
  display: flex;
  align-items: center;
  flex-direction: row;

  a:not(:last-child) {
    padding-right: 20px;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
